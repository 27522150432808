<template>
    <b-card class="top-row-section h-100" body-class="py-2">
        <b-row class="mb-3">
            <b-col>
                <h5 class="heavy text-primary text-uppercase mb-3">
                    {{ t('surgeonDetails_Personal') }}
                </h5>
            </b-col>
        </b-row>

        <b-form class="mx-0 px-0">
            <b-form-row>
                <b-col class="pr-sm-5" sm="6" lg="3">
                    <b-form-group
                        :label="t('surgeonDetails_SurgeonID')"
                        label-for="surgeon-id"
                        label-cols="3"
                        label-cols-sm="5"
                        label-class="surgeon-id-label"
                    >
                        <b-form-input
                            v-model="doctor.contactPartyNumber"
                            id="surgeon-id"
                            required
                            plaintext
                            autocomplete="off"
                        ></b-form-input>
                    </b-form-group>
                </b-col>
                <b-col class="pr-lg-5" sm="6" lg="5">
                    <b-form-group
                        :label="t('surgeonDetails_SurgeonStatus')"
                        label-for="surgeon-status"
                        label-cols="3"
                        label-cols-sm="4"
                    >
                        <b-form-checkbox
                            v-model="doctor.active"
                            @change="handleChange"
                            disabled="true"
                            switch
                            id="surgeon-status"
                            class="mr-3 custom-checkbox-label"
                        >
                            {{ t('surgeonDetails_Active') }}
                        </b-form-checkbox>
                    </b-form-group>
                </b-col>
            </b-form-row>

            <!-- Personal Information section of the card. -->
            <b-form-row>
                <b-col class="pr-sm-5" sm="6" lg="3">
                    <b-form-group
                        :label="t('surgeonDetails_FirstName')"
                        label-for="input-first-name"
                    >
                        <b-form-input
                            v-model="doctor.firstName"
                            id="input-first-name"
                            ref="firstName"
                            required
                            plaintext
                            autocomplete="off"
                        ></b-form-input>
                    </b-form-group>

                    <b-form-group :label="t('surgeonDetails_LastName')" label-for="input-last-name">
                        <b-form-input
                            v-model="doctor.lastName"
                            id="input-last-name"
                            required
                            plaintext
                            autocomplete="off"
                        ></b-form-input>
                    </b-form-group>
                </b-col>

                <b-col class="pr-lg-5" sm="6" lg="4">
                    <b-form-group
                        :label="
                            t('surgeonDetails_AssociatedAccounts') + `(${associatedAccountsCount})`
                        "
                        label-for="associated-accounts"
                    >
                        <ul class="list-unstyled">
                            <li
                                v-for="account in doctor.associatedAccounts"
                                :key="account.customerId"
                            >
                                <b>{{ account.name }}</b> #{{ account.ocosCustomerId }}
                            </li>
                        </ul>
                    </b-form-group>

                    <b-form-group
                        :label="t('surgeonDetails_PhoneNumber')"
                        label-for="input-phone-number"
                    >
                        <b-form-input
                            v-model="doctor.phoneNumber"
                            id="input-phone-number"
                            required
                            plaintext
                        ></b-form-input>
                    </b-form-group>
                </b-col>

                <b-col lg="5">
                    <b-form-row>
                        <b-col sm="12">
                            <b-form-group
                                :label="t('surgeonDetails_MainLocation')"
                                label-for="main-location"
                            >
                                <address>
                                    {{ doctor.address }}<br v-if="doctor.address" />
                                    {{ location }}
                                </address>
                                <address>
                                    <span class="label"> {{ t('emailAddress') }} </span>
                                    <a :href="`mailto:${doctor.email}`">{{ doctor.email }}</a>
                                </address>
                                <b-form-group>
                                    <template #label>
                                        <div class="d-flex justify-content-between">
                                            <span class="label">
                                                {{ t('notes') }}
                                            </span>
                                            <span class="text-caption text-gray-darker">
                                                {{ noteLength }}/100
                                            </span>
                                        </div>
                                    </template>
                                    <b-form-textarea
                                        v-model="doctor.notes"
                                        @change="handleChange"
                                        :disabled="!canEdit"
                                        rows="5"
                                        maxlength="100"
                                    >
                                    </b-form-textarea>
                                </b-form-group>
                            </b-form-group>
                        </b-col>
                    </b-form-row>
                </b-col>
            </b-form-row>
        </b-form>
    </b-card>
</template>
<script>
import {mapState, mapActions, mapGetters} from 'vuex';
import {ZoneCodes} from '@/constants/zone';
import get from 'lodash/get';
import debounce from 'lodash/debounce';
import {PERMISSIONS, PERMISSIONS_VALUES} from '@/constants/permissions';
import {checkPermissions} from '@/utilities/permissions';

export default {
    name: 'PersonalCard',
    props: {},
    data() {
        return {};
    },
    computed: {
        ...mapGetters('user', ['currentUser']),
        ...mapGetters('permissions', ['permissions']),
        ...mapState({
            doctor: (state) => state.doctors.selected,
        }),
        ...mapGetters({
            region: 'zone/currentZone',
        }),
        canEdit() {
            return this.checkPermissions({
                [PERMISSIONS.SURGEONS]: PERMISSIONS_VALUES.READ_WRITE,
            });
        },
        associatedAccountsCount() {
            console.log(this.doctor);
            return get(this.doctor, 'associatedAccounts.length', 0);
        },
        location() {
            return [this.doctor.city, this.doctor.state, this.doctor.zip, this.doctor.countryCode]
                .filter((x) => x)
                .join(', ');
        },
        noteLength: function () {
            return this.doctor?.notes?.length || 0;
        },
    },
    methods: {
        ...mapActions({
            updateDoctor: 'doctors/update',
        }),
        checkPermissions(permissionsToCheck) {
            return checkPermissions(
                permissionsToCheck,
                this.permissions,
                this.currentUser.accessPermissions
            );
        },
        handleChange: debounce(async function () {
            try {
                const {success, error} = await this.updateDoctor(this.doctor);
                if (!success) throw error;
            } catch (error) {
                this.$bvToast.toast(error?.errors?.join(' ') || this.t('somethingWentWrong'), {
                    title: error?.errorCodeDescription || this.t('warning'),
                    variant: 'warning',
                    solid: true,
                });
            }
        }, 1000),
    },
};
</script>
<style lang="scss" scoped>
::v-deep .custom-checkbox-label {
    padding-top: 10px;
    .custom-control-label {
        padding-top: 2px;
    }
}

::v-deep .form-control-plaintext {
    font-weight: 700;
    font-size: 1rem;
    line-height: 1.5rem;
}

::v-deep .col-form-label {
    font-size: 0.857rem;
    line-height: 2;
}
</style>
